.SocialLinks {
	margin: 2em 0;

}

.SocialLinks table {
	margin: 0px auto;
}

.SocialLink {
	color: #999;
	
	-moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.SocialLink:visited {
	color: #999;
}

.SocialLink:hover {
	color: #E99;
}

.SocialLink.GitHubIcon:hover { color: #6e5494; }

.SocialLink.TwitterIcon:hover {	color: #1da1f2; }

.SocialLink.FacebookIcon:hover { color: #3b5998; }

.SocialLink.CodepenIcon:hover { color: #0ebeff; }

.SocialLink.WebPageIcon:hover {	color: #E99; }

.SocialIcon {
	display: block;
	padding: 0px 10px;
}

.Copyright {
	font-family: monospace;
    font-size: 0.9em;
    text-align: center;
    color: #999;
    margin: 50px 0px;
	text-decoration: none;
	cursor: default;

	-moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.Copyright:hover {
	color: rgb(256, 128, 128);
  }
